<template>
  <div>
    <Navbar />
    <div class="mb-auto">
      <UserInfo class="container mt-5" />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import UserInfo from '@/components/Profile/UserInfo'

export default {
  components: {
    Navbar,
    UserInfo
  }
}
</script>

<style scoped>
.container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
